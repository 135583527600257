

/* ACCORDION OVERRIDES */
.accordion-button {
    padding: 1.5rem; /* Default padding */
}

.accordion-button::after {
    color: var(--primary); /* Icon color */
}

.accordion-button:not(.collapsed)::after {
    color: var(--secondary); /* Active icon color */
}

.accordion-button::before {
    content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23%{var(--primary)}'><path fill-rule='evenodd' d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/> <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/></svg>");
}

.accordion-button:not(.collapsed)::before {
    content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23%{var(--secondary)}'><path fill-rule='evenodd' d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>  <path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/></svg>");
}

/* Button styling */
.btn-rounded {
    position: relative;
    padding: 0.8375rem 4.375rem 0.8375rem 1.25rem;
    font-weight: 700;
    margin: 0;
    border: 0.1rem solid rgba(var(--white), 0.6);
    background-color: var(--white);
    display: block;
}

/* Span inside .btn-rounded */
.btn-rounded span {
    position: absolute;
    background-color: var(--primary);
    width: 2.5rem;
    height: 2.5rem;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    border-radius: 50%;
}

/* Icon inside span */
.btn-rounded span i {
    color: var(--white);
    font-size: 1rem;
    line-height: 2.5rem;
}

/* Hover state */
.btn-rounded:hover {
    background: linear-gradient(45deg, var(--primary), var(--secondary)); /* Example gradient */
    color: var(--white);
}

/* Span inside .btn-rounded on hover */
.btn-rounded:hover span {
    background-color: rgba(var(--black), 0.6);
}

/* Pulse Border */ 

@keyframes pulse-border {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}

/* Use this class to apply the gradient background */
.gradient-background {
    background: linear-gradient(to right, rgba(153, 38, 240, 0.95), rgba(209, 34, 227, 0.95));
}

/* Use this class to center an element absolutely */
.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Use this class to apply transition properties */
.transition-property {
    transition-duration: 1s;
    transition-property: all;
}

/* Base style for .display-2 */
.display-2 {
    margin-bottom: 1rem;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
}

/* Modifier for .display-2--intro */
.display-2--intro {
    display: inline-block;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}

/* Modifier for .display-2--description */
.display-2--description {
    font-size: 1rem;
    display: block;
    margin-top: 1.2rem;
    text-transform: none;
}

/* Media query for .display-2--description on large screens */
@media (min-width: 992px) { /* lg breakpoint */
    .display-2--description {
        font-size: 1.4rem;
        display: inline-block;
    }
}

/* Pseudo-elements for .heading-line */
.heading-line::before {
    content: "";
    width: 10rem;
    height: 0.3rem;
    display: block;
    margin: 0 auto;
    background-color: #9926f0; /* Replace with $primary value */
}

.heading-line::after {
    content: "";
    width: 10rem;
    padding-top: 0rem;
    height: 0.1rem;
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;
    background-color: #9926f0; /* Replace with $primary value */
}

/* Base style for .display-3 */
.display-3 {
    font-family: 'Poppins', sans-serif;
}

/* Modifier for .display-3--title */
.display-3--title {
    font-size: 1.2rem;
    text-transform: capitalize;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    display: inline-block;
    background-image: linear-gradient(to right, #9926f0, #d122e3); /* Replace with $primary and $secondary values */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Media query for .display-3--title on large screens */
@media (min-width: 992px) { /* lg breakpoint */
    .display-3--title {
        font-size: 1.8rem;
    }
}

/* Base style for .border-right */
.border-right {
    border-right: 0;
}

/* Media query for .border-right on small screens */
@media (min-width: 576px) { /* sm breakpoint */
    .border-right {
        border-right: 0.1rem solid #d1d5db; /* Replace with $gray-300 value */
        height: 100%;
    }
}

/* Base style for .border-right */
.border-left {
    border-left: 0;
}

/* Media query for .border-right on small screens */
@media (min-width: 576px) { /* sm breakpoint */
    .border-left {
        border-left: 0.1rem solid #d1d5db; /* Replace with $gray-300 value */
        height: 100%;
    }
}

/* Gradient background */
.gradient {
    background: linear-gradient(to right, rgba(153, 38, 240, 0.95), rgba(209, 34, 227, 0.95)); /* Replace with $primary and $secondary values */
}

/* Base style for .campanies */
.campanies {
    padding: 0 0 5rem 0;
}

/* Style for .campanies__logo-box */
.campanies__logo-box {
    max-width: 100%;
    height: 3rem;
    margin-bottom: 2rem;
    padding: 0.5rem;
    text-align: center;
    position: relative;
}

/* Style for img inside .campanies__logo-box */
.campanies__logo-box img {
    height: 100%;
}

/* Hover effect for img inside .campanies__logo-box */
.campanies__logo-box img:hover {
    filter: grayscale(120%);
}

/* Base style for .footer */
.footer {
    padding-top: 1rem;
    background-color: #151414; /* Replace with the appropriate color value for $footer */
}

/* Style for .contact-box */
.contact-box {
    position: relative;
}

/* Style for .contact-box__icon */
.contact-box__icon {
    width: 2rem;
    stroke: #9926f0; /* Replace with the appropriate color value for $primary */
    padding-top: 0.5rem;
}

@media (min-width: 576px) {
    .contact-box__icon {
        width: 4rem;
    }
}

/* Style for .contact-box__info */
.contact-box__info {
    padding: 0.4rem;
    font-weight: 600;
}

/* Style for .contact-box__info--title */
.contact-box__info--title {
    color: #ffffff; /* Replace with the appropriate color value for $white */
    text-decoration: none;
    font-size: 1rem;
}

@media (min-width: 576px) {
    .contact-box__info--title {
        font-size: 1.4rem;
    }
}

/* Style for .contact-box__info--subtitle */
.contact-box__info--subtitle {
    font-size: 0.8rem;
    color: #6c757d; /* Replace with the appropriate color value for $gray-600 */
    text-decoration: none;
}

@media (min-width: 576px) {
    .contact-box__info--subtitle {
        font-size: 1rem;
    }
}

/* Style for .footer-sm */
.footer-sm a {
    color: #ffffff; /* Replace with the appropriate color value for $white */
}

.footer-sm a i {
    font-size: 1rem;
    margin-left: 1rem;
}

@media (min-width: 576px) {
    .footer-sm a i {
        font-size: 1.6rem;
    }
}

.footer-sm a:hover {
    color: #9926f0; /* Replace with the appropriate color value for $primary */
}

/* Style for .campany-list */
.campany-list {
    display: block;
    list-style-type: none;
    position: relative;
}

.campany-list li {
    margin-bottom: 0.5rem;
    text-decoration: none;
}

.campany-list li a {
    color: #ffffff; /* Replace with the appropriate color value for $white */
    text-decoration: none;
}

.campany-list li a:hover {
    color: #9926f0; /* Replace with the appropriate color value for $primary */
}

/* Style for .footer-bottom */
.footer-bottom {
    background-color: #000000; /* Replace with the appropriate color value for $black */
}

/* Style for .footer-bottom__copyright */
.footer-bottom__copyright {
    color: #6c757d; /* Replace with the appropriate color value for $gray-600 */
    font-weight: 400;
}

.footer-bottom__copyright a {
    color: #adb5bd; /* Replace with the appropriate color value for $gray-300 */
    text-decoration: none;
}

.footer-bottom__copyright a:hover {
    color: #9926f0; /* Replace with the appropriate color value for $primary */
    text-decoration: underline;
}

/* Style for .back-to-top */
.back-to-top {
    position: fixed;
    width: 3rem;
    height: 3rem;
    right: 1.875rem;
    bottom: 1.875rem;
    z-index: 9999;
}

.back-to-top i {
    color: #ffffff; /* Replace with the appropriate color value for $white */
    font-size: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Style for .get-started */
.get-started {
    padding: 4rem 0;
    background-color: #f8f9fa; /* Replace with the appropriate color value for $gray-200 */
}

/* Style for .cta-info */
.cta-info {
    padding: 3rem;
    margin: 1rem 0;
}

/* Style for .cta-info__list */
.cta-info__list {
    position: relative;
    list-style-type: none;
    display: block;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    margin-bottom: 0.7rem;
}

.cta-info__list li::before {
    font-family: "Font Awesome 5 Free";
    content: "\f00c"; /* Font Awesome checkmark */
    font-weight: 600;
    margin-right: 0.5rem;
    font-size: 1.2rem;
}

/* Style for .form */
.form {
    padding: 3rem;
}

/* Style for .form button */
.form button {
    border: none;
    color: #ffffff; /* Replace with the appropriate color value for $white */
    text-transform: capitalize;
}

/* Style for .form button a */
.form button a {
    color: #ffffff; /* Replace with the appropriate color value for $white */
    text-decoration: none;
    text-transform: capitalize;
}

/* Style for .intro-section */
.intro-section {
    background: linear-gradient(to right, rgba(153, 38, 240, 0.95), rgba(219, 18, 227, 0.95)); /* Replace with appropriate gradient values */
    padding: 10rem 0 0 0;
    width: 100%;
    height: 100%;
}

/* Style for .video-box */
.intros .video-box {
    position: relative;
}

.intros .video-box a span i {
    font-size: 6rem;
    color: #d122e3; /* Replace with the appropriate color value for $secondary */
}

/* Style for .border-animation */
.border-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 6rem;
    height: 6rem;
    border: 0.55rem solid #ffffff; /* Replace with the appropriate color value for $white */
    border-radius: 50%;
    animation: pulse-border 1.5s linear infinite;
}

/* Style for .border-animation--border-1 */
.border-animation--border-1 {
    animation-delay: 1s;
}

/* Style for .border-animation--border-2 */
.border-animation--border-2 {
    animation-delay: 1.5s;
}

/* Keyframes for pulse-border animation */
@keyframes pulse-border {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}

/* Style for .menu */
.menu {
    background: linear-gradient(to right, rgba(153, 38, 240, 0.95), rgba(219, 18, 227, 0.95)); /* Replace with appropriate gradient values */
}

/* Style for menu items */
.menu li {
    padding: 0 0.7rem;
}

.menu li a {
    color: #ffffff; /* Replace with the appropriate color value for $white */
    padding: 0 0.7rem;
    text-transform: capitalize;
    font-weight: 600;
}

/* Hover effect for menu items */
.menu li:hover {
    color: #ffffff; /* Replace with the appropriate color value for $white */
}

/* Style for .portfolio */
.portfolio {
    padding: 4rem 0;
}

.portfolio button {
    text-transform: capitalize;
}

/* Style for .portfolio-box */
.portfolio-box {
    position: relative;
    width: 100%;
    height: 18.5rem;
    overflow: hidden;
    margin-bottom: 1.2rem;
    border-top: 0.5rem solid #b07fff; /* Replace with the appropriate color for lighten($primary, 20%) */

    /* Ensure to replace #b07fff with the correct color code */
}

/* Style for images inside .portfolio-box */
.portfolio-box img {
    width: 100%;
    height: 100%;
}

/* Style for .portfolio-info */
.portfolio-box .portfolio-info {
    background-color: rgba(153, 38, 240, 0.75); /* Replace with rgba($primary, 0.75) */
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s, visibility 1s; /* Replace @include transitionProperty */

    /* Style for the caption inside .portfolio-info */
    .portfolio-info .caption {
        text-align: center;
        color: #ffffff; /* Replace with the appropriate color for $white */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); /* Replace @include absoluteCenter */

        /* Style for h4 inside .caption */
        h4 {
            text-transform: uppercase;
            display: block;
        }
    }
}

/* Hover effects for .portfolio-box */
.portfolio-box:hover img {
    transform: scale(1.5);
    transition: transform 1s; /* Replace @include transitionProperty */
}

.portfolio-box:hover .portfolio-info {
    opacity: 1;
    visibility: visible;
    transition: opacity 1s, visibility 1s; /* Replace @include transitionProperty */
}

/* Style for .services */
.services {
    position: relative;
    padding: 1rem 0;
}

/* Style for .services__content */
.services__content {
    padding: 1rem;
    margin-top: 0;
    height: 20em;
    display: flex;
    flex-direction: column;
    align-content: center;
}

/* Responsive font size for paragraphs inside .services__content */
.services__content p {
    font-size: 0.8rem;
    transition: font-size 0.3s; /* Smooth transition for font size change */

    /* Media query for larger screens */
    @media (min-width: 992px) { /* Assuming lg breakpoint is 992px */
        font-size: 1rem;
    }
}

/* Media query for larger screens */
@media (min-width: 992px) { /* Assuming lg breakpoint is 992px */
    .services__content {
        margin-top: 2.5rem;
    }

    .services__content .icon {
        font-size: 2.5rem;
    }
}

/* Style for .services__pic */
.services__pic {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
}

/* Style for .services__content .icon */
.services__content .icon {
    font-size: 1.5rem;
    color: #9926f0; /* Replace with the appropriate color for $primary */
    transition: font-size 0.3s; /* Smooth transition for font size change */
}

/* Style for .testimonials */
.testimonials {
    position: relative;
    padding: 0;
    background: linear-gradient(to right, rgba(153, 38, 240, 0.95), rgba(209, 34, 227, 0.95)); /* Replace with appropriate gradient */
}

/* Style for .testimonials__card */
.testimonials__card {
    background-color: rgba(255, 255, 255, 0.8); /* Replace with the appropriate color for $white */
    padding: 2rem 1.25rem;
    border-radius: 0.625rem;
    height: auto;
    text-align: center;
}

/* Style for icons inside .testimonials__card */
.testimonials__card i {
    font-size: 1.5rem;
    color: rgba(153, 38, 240, 0.7); /* Replace with the appropriate color for $primary */
}

/* Style for .ratings icons */
.testimonials__card .ratings i {
    color: rgba(153, 38, 240, 0.7); /* Replace with the appropriate color for $primary */
    font-size: 1rem;
}

/* Style for .testimonials__picture */
.testimonials__picture {
    width: 6rem;
    height: 6rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Style for images inside .testimonials__picture */
.testimonials__picture img {
    border: 0.325rem solid rgba(153, 38, 240, 0.7); /* Replace with the appropriate color for $primary */
}

/* Style for .testimonials__name */
.testimonials__name {
    padding-top: 3rem;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 500;
    color: #ffffff; /* Replace with the appropriate color for $white */
}

/* Style for h3 inside .testimonials__name */
.testimonials__name h3 {
    padding-top: 0.8rem;
    text-transform: capitalize;
}

/* Style for p inside .testimonials__name */
.testimonials__name p {
    padding-bottom: 2rem;
    text-transform: capitalize;
}

.project-image {
    border-radius: 5em;
    max-height: 20em;
    box-shadow: 0.5em 1em 1.5em grey;
}

